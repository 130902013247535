<template>
    <modal ref="modalAgregarCuenta" titulo="Crear cuenta de pasarela de pago" tamano="modal-lg" no-aceptar adicional="Crear" @adicional="agregar">
        <ValidationObserver ref="form" class="w-100">
            <div class="row mx-0 justify-center">
                <div class="col-6">
                    <ValidationProvider v-slot="{errors}" rules="required" name="nombre">
                        <p class="f-12 text-general pl-3 f-500 mt-3">Nombre de la cuenta</p>
                        <el-input v-model="model.nombre" size="small" placeholder="Nombre" class="w-100" />
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required" name="api_key">
                        <p class="f-12 text-general pl-3 f-500 mt-3">Api Key</p>
                        <el-input v-model="model.api_key" size="small" placeholder="api_key" class="w-100" />
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required" name="api_login">
                        <p class="f-12 text-general pl-3 f-500 mt-3">Api Login</p>
                        <el-input v-model="model.api_login" size="small" placeholder="api_login" class="w-100" />
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required" name="descripcion">
                        <p class="f-12 text-general pl-3 f-500 mt-4">Descripción</p>
                        <el-input v-model="model.descripcion" placeholder="Descripción de la Cuenta" type="textarea" :rows="5" class="w-100" />
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                </div>
                <div class="col-6">
                    <ValidationProvider v-slot="{errors}" rules="required" name="merchant_id">
                        <p class="f-12 text-general pl-3 f-500 mt-3">Merchandt ID</p>
                        <el-input v-model="model.merchant_id" size="small" placeholder="merchant_id" class="w-100" />
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required" name="account_id">
                        <p class="f-12 text-general pl-3 f-500 mt-3">Account ID</p>
                        <el-input v-model="model.account_id" size="small" placeholder="account_id" class="w-100" />
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <ValidationProvider v-slot="{errors}" rules="required" name="moneda">
                        <p class="f-12 text-general f-500 pl-3 mt-3">Moneda</p>
                        <el-select v-model="model.idm_moneda" size="small" placeholder="seleccionar" class="w-100">
                            <el-option
                            v-for="item in selectMonedas"
                            :key="item.id"
                            :label="item.nombre"
                            :value="item.id"
                            />
                        </el-select>
                        <span class="text-danger w-100 f-11 pl-3"> {{ errors[0] }} </span>
                    </ValidationProvider>
                    <p class="f-12 text-general f-500 pl-3 mt-4">Seleccionar Cedis</p>
                    <el-select v-model="id_cedis" size="small" placeholder="seleccionar" class="w-100" @change="addCedis">
                        <el-option
                        v-for="item in selectCedis"
                        :key="item.id"
                        :label="item.nombre"
                        :value="item.id"
                        :disabled="item.disabled"
                        />
                    </el-select>
                    <div v-for="(c,key) in cedisAgregados" :key="key" class="row mx-0 bg-light-f5 border align-items-center pl-2 mt-2 br-5 f-14" style="height:32px;">
                        {{ c.nombre }} <i class="icon-cancel ml-auto mr-2 text-general f-16 cr-pointer" @click="removerCedis(c.id)" />
                    </div>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>

<script>
import Pagos from "~/services/pasarela-tesoreria/pagos"
export default {
    data(){
        return{
            id_cedis: '',
            model:{
                nombre:'',
                descripcion:'',
                merchant_id:'',
                api_key:'',
                account_id:'',
                api_login:'',
                idm_moneda:'',
            },
            cedisAgregados:[]
        }
    },
    computed:{
        selectMonedas(){
            return this.monedas.filter(o=>o.sigla !== 'EUR')
        },
        selectCedis(){
            return this.cedis_calculo.filter(o=>o.id != 0)
        }
    },
    methods: {
        toggle(){
            this.$refs.modalAgregarCuenta.toggle();
        },
        addCedis(id){
            let c = this.selectCedis.find(o=>o.id === id)
            c.disabled = true
            this.cedisAgregados.push(c)
            this.id_cedis = null
        },
        removerCedis(id){
            this.selectCedis.find(o=>o.id === id).disabled = false
            let c = this.selectCedis.find(o=>o.id === id)
            this.cedisAgregados.splice(this.cedisAgregados.indexOf(c), 1)

        },
        async agregar(){
            try {

                const valid = await this.$refs.form.validate()
                if(!valid) return

                let cedis = []
                for (const i of this.cedisAgregados){
                    cedis.push(i.id)
                }
                this.model.cedis = cedis

                const {data} = await Pagos.crearCuenta(this.model)
                this.notificacion('','Cuenta agregada correctamante','success')
                this.$emit('update')
                this.$refs.modalAgregarCuenta.toggle();

            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
